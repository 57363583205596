import request from '@/utils/request'

export function getList(data) {
  return request({
    url: '/adminis/type/index',
    method: 'post',
    data
  })
}

export function getInfo(data) {
  return request({
    url: '/adminis/log/index',
    method: 'post',
    data
  })
}

export function infoChange(data) {
  return request({
    url: '/adminis/log/get_under',
    method: 'post',
    data
  })
}

export function addInfo(data) {
  return request({
    url: '/adminis/log/add',
    method: 'post',
    data
  })
}

export function delInfo(data) {
  return request({
    url: '/adminis/log/del',
    method: 'post',
    data
  })
}