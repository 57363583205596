<template>
    <div class="page_main">
        <div class="top_nav">
            <img src="@/assets/home_top_bg.png" alt="">
            <div class="title">哈密供电段油化验分析管理系统</div>
        </div>
        <div class="system_item_box">
            <div class="list_item" v-for="(item, index) in homeList" :key="index" @click="goSecond(item)">
                {{ item.type_name }}
            </div>
        </div>
    </div>
</template>

<script>
import { getList } from '@/api/user.js'
export default {
    name: 'IndexHome',

    data() {
        return {
            homeList: []
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        goSecond(row) {
            this.$router.push({
                path: '/second',
                query: {
                    id: row.id,
                    name: row.type_name
                }
            })
        },
        getList() {
            const params = {
                level: 1
            }
            getList(params).then(res => {
                this.homeList = res.data.res
            })
        }
    },
};
</script>
<style scoped lang="scss">
.page_main {
    width: 100%;
    height: 100vh;
    background: url('@/assets/home_bg.png') no-repeat 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top_nav {
        width: 100%;
        height: auto;
        background-size: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .title {
            position: absolute;
            margin: auto;
            font-weight: 700;
            font-size: 44px;
            left: 0;
            right: 0;
            top: 40px;
            color: #fff;
        }
    }

    .system_item_box {
        width: 100%;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .list_item {
            width: 404px;
            height: 134px;
            margin: 0 47px;
            background: url('@/assets/item_bg.png') no-repeat;
            background-size: 100% 100%;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 700;
            font-size: 40px;
            letter-spacing: 4px;
            font-style: normal;
            text-transform: none;
            color: #51FFF9;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                color: #00FFDD;
                background: url('@/assets/item_bg_a.png') no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>