import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexHome from '../views/index/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexHome
  },
  {
    path: '/second',
    name: 'second',
    component: () => import(/* webpackChunkName: "about" */ '../views/second/index.vue')
  },
  {
    path: '/third',
    name: 'third',
    component: () => import(/* webpackChunkName: "about" */ '../views/third/index.vue')
  },
  {
    path: '/testData',
    name: 'testData',
    component: () => import(/* webpackChunkName: "about" */ '../views/testData/index.vue')
  },
  {
    path: '/inputView',
    name: 'inputView',
    component: () => import(/* webpackChunkName: "about" */ '../views/inputView/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
